import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import PageContext from "../context"
import caseStudy from "../images/ldj-case-study.png"

import "./index.scss"
import "./fast.scss"

const Fast = ({ handleCTA }) => (
  <Layout className="layout fast">
    <SEO title="Fast workshop" />
    <PageContext.Consumer>
      {data => (
        <header className="hero">
          <div className="content">
            <h1>Fast Problem Solving Workshop</h1>
            <p>
              A lightning-fast workshop to tackle existing problems, align your
              team and to generate potential solutions that can be implemented
              immediately
            </p>
            <button className="secondary" onClick={data.scrollToContact}>
              Book a call
            </button>
          </div>
        </header>
      )}
    </PageContext.Consumer>
    <section className="problem">
      <h2>The Problem</h2>
      <p>
        This workshop is a 90 minutes long remedy for companies who are
        struggling to cope with the unpredictable landscape that's been created
        by COVID-19. Shifting to remote work is{" "}
        <strong>
          burning out our workforce, and making it harder to innovate
        </strong>{" "}
        at a time where we need to constantly reinvent how our business
        operates.
      </p>
    </section>
    <section className="case strip">
      <section className="content">
        <div>
          <h2>Case Study</h2>
          <p>
            When the team at{" "}
            <a href="http://firstdag.com" target="_blank" rel="noreferrer">
              First DAG
            </a>{" "}
            started planning the next version of their Enterprise-Grade digital
            asset wallet, we used this workshop to get the entire team involved
            in identifying what is currently working well and what needs to be
            improved. We were then able to generate many different fresh
            solutions, prioritize them, and finally, turn them into actionable
            tasks.
          </p>
        </div>
        <img
          src={caseStudy}
          alt="The group at First DAG running a short workshop"
        />
      </section>
    </section>
    <section className="how">
      <h2>How it works</h2>
      <ul>
        <li>
          <p className="number">1</p>
          <h3>Get Together</h3>
          <p>
            Remote or on-site, we start by coming together as a
            multi-disciplinary team with members from your organization and ours
          </p>
        </li>
        <li>
          <p className="number">2</p>
          <h3>Workshop</h3>
          <p>
            The workshop itself takes around one hour, where we will facilitate
            the progression from unpacking the problem, through generating
            solutions, to deciding on the best ones
          </p>
        </li>
        <li>
          <p className="number">3</p>
          <h3>Experiment</h3>
          <p>
            By the end of the workshop, your team would have created three
            well-formed, actionable experiments which you could start
            implementing immediately
          </p>
        </li>
      </ul>
    </section>
    <section className="price">
      <h2>
        Book a workshop today to find new business opportunities during COVID-19
      </h2>
      <h3>A flat fee of $999</h3>
      <ul>
        <li>
          <h4>Experienced Facilitators</h4>
          <p>
            An experienced facilitator will guide the team throughout the
            process
          </p>
        </li>
        <li>
          <h4>Increased alignment</h4>
          <p>
            Get the team on the same page, where all members have a clear idea
            of where we’re going
          </p>
        </li>
        <li>
          <h4>Our Experts</h4>
          <p>
            Our team of industry experts will help you get better results out of
            the workshop
          </p>
        </li>
        <li>
          <h4>Social Gathering</h4>
          <p>
            In a time when we’re physically (and mentally) remote, having the
            chance to get together as a team is even more important
          </p>
        </li>
        <li>
          <h4>Actionable</h4>
          <p>
            Get a prioritized list of short experiments you could start
            implementing immediately
          </p>
        </li>
        <li>
          <h4>Predictable</h4>
          <p>
            An experienced facilitator to guide the team through the process
          </p>
        </li>
      </ul>
    </section>
  </Layout>
)

export default Fast
